import React from 'react'
import { useLocation } from 'react-router-dom'
import { Space } from 'antd-mobile'
import { CheckCircleFill } from 'antd-mobile-icons'
import './register.css'
export default function Success() {
  const {state:{code,name,unit,startTime,endTime,visitPurpose}}=useLocation()
  return (
    <Space direction='vertical' align='center' style={{width:'100%','--gap': '24px',padding:'77px 31px 0'}}>
        <CheckCircleFill fontSize={58} color={'#2F5FBE'}/>
        <p className='success-title'>Registration Successful</p>
        
        {code===200?
        <><p className='success-content'>You may now enter by scanning your face at the VMS Device. Thank you and stay safe.</p>
        <div className='success-content'>Visiting Unit: {unit}</div>
        <div className='success-content'>Visiting Name: {name}</div>
        <div className='success-content'>Visit Purpose: {visitPurpose}</div>
        <div className='success-content'>Start Date: {startTime}</div>
        <div className='success-content'>End Date: {endTime}</div></>:
        <><p className='success-content'>Please note that your registration is subject to approval by the visitee and a notification will be sent to you upon confirmation. 
        Please check your email inbox. Thank you and stay safe. </p>
        <div className='success-content'>Visiting Unit: {unit}</div>
        <div className='success-content'>Visiting Name: {name}</div>
        <div className='success-content'>Visit Purpose: {visitPurpose}</div>
        <div className='success-content'>Start Date: {startTime}</div>
        <div className='success-content'>End Date: {endTime}</div>
        </>
        }
    </Space>
  )
}
