import PropTypes from 'prop-types'
import React, { useRef, useState,useEffect } from 'react'
import {useNavigate,useSearchParams} from 'react-router-dom'
import {
    Form,
    Input,
    Button,
    Dialog,
    Stepper,
    Checkbox,
    Space,
    Picker,
    ImageUploader,
    CascadePicker,
    DatePickerView,
    Popup,
    Radio,
    Toast,
    Image,
    ConfigProvider,
    SpinLoading
  } from 'antd-mobile'
  import dayjs from 'dayjs'
  import './register.css'
  import add from '../Images/add.png'
  import network from '../Images/network.png'
  import photo1 from '../Images/photo1.png'
  import photo2 from '../Images/photo2.png'
  import cssLogo from '../Images/cssLogo.png'
  import enUS from 'antd-mobile/es/locales/en-US'
  import axios from 'axios'
  import { DownOutline } from 'antd-mobile-icons'
  import Base64 from 'base-64';
  
  
  const visitPurposeColumnsInit = [
    [
      { label: 'Visitor', value: 1 },
      { label: 'Overnight', value:2  },
      { label: 'Event', value: 3 },
      { label: 'Contractor/Worker', value: 4 },
      { label: 'Drop Off/Pick Up', value: 5 },
      { label: 'Re-invite',value:6}
    ],
  ]
const  VisitorMobile =()=> {
// const search=useSearchParams()
 const url= window.location.pathname
 const netWork=window.navigator.onLine
 const urlArr=url.split('/')
 const registerType=urlArr[1]==='pre-register'?1:2
 const propCode=urlArr[2] || ''
 const cssUniqueKey=urlArr[1]==='pre-register'?urlArr[3]:''
 const cssUniqueKeyArr=Base64.decode(cssUniqueKey).split('|')
 const visitorId=urlArr[1]==='pre-register'?urlArr[4]: urlArr[3]
 const Noref=useRef()
 const checkRef=useRef()
 const ng = useNavigate();
 const [form] = Form.useForm();
 const [visible, setVisible] = useState(false)
 const [startTime,setStartTime]=useState(null)
 const [endTime,setEndTime]=useState(null)
 const [isDate,setIsDate]=useState('start')
 const [date,setDate]=useState('')
 const [fileList1,setFileList1]=useState([])
 const [fileList2,setFileList2]=useState([])
 const [file1,setFile1]=useState(null)
 const [file2,setFile2]=useState(null)
 const [agree,setAgree]=useState(false)
 const [isSelect,setIsSelect]=useState(true)
 const [identity,setIdentity]=useState(1)
 const [options1,setOptions1]=useState([])
 const [options2,setOptions2]=useState([])
 const [options3,setOptions3]=useState([])
 const [property,setProperty]=useState('')
 const [logoPath,setLogoPath]=useState('')
 const [deptDscp,setdeptDscp]=useState('')
 const [isAbled,setIsAbled]=useState(true)
 const [isAbled2,setIsAbled2]=useState(true)
 const [name,setName]=useState('')
 const [loading,setLoading]=useState(true)
 const [isLoaing2,setIsLoading2]=useState(false)
 const [isLoaing1,setIsLoading1]=useState(false)
 const [isLoading,setIsLoading]=useState(false)
 const [unit,setUnit]=useState('')
 const [purpose,setPurpose]=useState('')
 const [visitPurposeColumns,setVisitPurposeColumns] = useState(visitPurposeColumnsInit)
 const onFinish = (values) => {
    if(agree){
      setLoading(false)
      const data={...values,
        startTime,
        endTime,
        passportFile:file1,
        selfieFile:file2,
        blockCode:values.address[0],
        floorCode:values.address[1],
        unitCode:values.address[2],
        deptCode:values.deptCode[0],
        visitPurpose:values.visitPurpose[0],
        purposename:purpose,
        registerType:registerType,
        propCode:propCode,
        deptDscp:deptDscp
      }
      if(visitorId){
        data.visitorId=visitorId
      }
      if(cssUniqueKey){
        data.cssUniqueKey=cssUniqueKey
        data.deptCode=cssUniqueKeyArr[2]
        data.blockCode=cssUniqueKeyArr[3]
        data.floorCode=cssUniqueKeyArr[4]
        data.unitCode=cssUniqueKeyArr[5]
        data.deptDscp=options1.flat().find(item=>item.code===cssUniqueKeyArr[2]).name
      }
      const formData=new FormData();
      for (const key in data) {
        if(data[key]){
          formData.append(key, data[key]);
        }
      }
      axios({
        url:'/api/member/v2/register',
        method:'post',
        data:formData,
        headers:{
          'Content-Type': 'multipart/form-data',
        }
      }).then(res=>{
        if(res.data.code ===200 || res.data.code===201){
          setLoading(true)
          ng('/success',{state:{code:res.data.code,name:data.fullName,unit:unit ,startTime:data.startTime,endTime:data.endTime,
            visitPurpose:purpose
          }})
        }else{
          Toast.show({duration:'2000',content: res.data.msg})
        }
        // Dialog.alert({
        //   confirmText:'OK',
        //   content:faceFaild,
        // })
      }).catch((error)=>{
        Toast.show({duration:'2000',content: 'error'})
      }).finally(()=>{
        setLoading(true)
        if(document.getElementsByClassName('upload-image2')[0]&&document.getElementsByClassName('upload-image1')[0]){
          document.getElementsByClassName('upload-image2')[0].style.display='none'
          document.getElementsByClassName('upload-image1')[0].style.display='none'
        }
      })
    }else{
      Toast.show({duration:'2000',content: 'To continue，you must agree to Privacy Clause'})
    }
  }
 const faceFaild=(<Space direction='vertical' align='center' style={{padding:'0 12px'}}>
 <div className='face-faild-title'>
   Confirmation failed
 </div>
 <div className='face-faild-content'>
   Selfie photo is not acceptable，Please upload again
 </div>
 </Space>)
 const monthShow=(data)=>{
  switch (data) {
    case 1:
      return 'Jan'
    case 2:
      return 'Feb'
    case 3:
      return 'Mar'
    case 4:
      return 'Apr'
    case 5:
      return 'May'
    case 6:
      return 'Jun'
    case 7:
      return 'Jul'
    case 8:
      return 'Aug'
    case 9:
      return  'Sept'
    case 10:
      return 'Oct'
    case 11:
      return 'Nov'
    case 12:
      return 'Dec'
    default:
      return data
  } 
 }
 //日期展示
 const labelRenderer = (type, data) => {
  switch (type) {
    case 'month':
      return monthShow(data)
    case 'hour':
      return data + 'h'
    case 'minute':
      return data + 'm'
    default:
      return data
  }
}
const Upload1 = async (file) => {
  let blobToFile1 = new window.File([file], `fileName1.${file.type.split('/')[1]}`, {type:file.type})
  document.getElementsByClassName('upload-image1')[0].style.display='none'
  setFile1(blobToFile1)
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({
        url: reader.result,
      });
    };
  });
};
const Upload2 = async (file) => {
  let blobToFile2 = new window.File([file], `fileName2.${file.type.split('/')[1]}`, {type:file.type})
  document.getElementsByClassName('upload-image2')[0].style.display='none'
  setFile2(blobToFile2)
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({
        url: reader.result,
      });
    };
  });
};
const beforeUpload=(file)=>{
  if (file.size >10* 1024 * 1024) {
    Toast.show('请选择小于 10M 的图片')
    return null
  }
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = document.createElement('img');
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const widthC=img.naturalWidth/5*0.8
        const hightC=img.naturalHeight/5*0.8
        canvas.width = img.naturalWidth/5;
        canvas.height = img.naturalHeight/5;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0,img.naturalWidth/5,img.naturalHeight/5);
          ctx.fillStyle = 'black';
          ctx.textBaseline = 'bottom';
          // ctx.rotate(-40*Math.PI/180)
          // ctx.font = `italic ${img.naturalWidth/5/15}px Arial`;
          // ctx.fillText('CSS DECISIONS1', -(Math.cos(40*Math.PI/180) * (hightC/2)),
          // hightC/2+(hightC/2-hightC/2*(Math.sin(40*Math.PI/180))));
          // ctx.fillText('CSS DECISIONS2', -(Math.sin(45*Math.PI/180) * (img.naturalHeight/5)), img.naturalHeight/5*0.8);
          // ctx.fillText('CSS DECISIONS3', img.naturalWidth/5/2-(Math.sin(45*Math.PI/180) * 
          // (img.naturalWidth/5/2)), img.naturalHeight/5/2+(Math.sin(45*Math.PI/180) * (img.naturalWidth/5/2)));
          // ctx.fillText('CSS DECISIONS4', (-(Math.sin(45*Math.PI/180) * (img.naturalWidth/5/2))*0.7), 
          // (img.naturalHeight/5/2+(Math.sin(45*Math.PI/180) * (img.naturalHeight/5))));
          ctx.rotate(-40*Math.PI/180)
          ctx.font = `italic ${img.naturalWidth/5/15}px Arial`;
          ctx.fillText('CSS DECISIONS', -hightC/2*(Math.cos(40*Math.PI/180)),
           hightC/2*(Math.sin(40*Math.PI/180))+hightC/2*(Math.cos(40*Math.PI/180))*(Math.tan(20*Math.PI/180)));

          ctx.fillText('CSS DECISIONS', -hightC*(Math.cos(40*Math.PI/180)),
          hightC*(Math.sin(40*Math.PI/180))+hightC*(Math.cos(40*Math.PI/180))*(Math.tan(20*Math.PI/180)));

          ctx.fillText('CSS DECISIONS', widthC-hightC*(Math.cos(40*Math.PI/180)), 
          hightC/2*(Math.sin(40*Math.PI/180))+hightC/2*(Math.cos(40*Math.PI/180))*(Math.tan(20*Math.PI/180))+hightC/2);
          
          ctx.fillText('CSS DECISIONS',  widthC/2-hightC*(Math.cos(40*Math.PI/180)),
          hightC*(Math.sin(40*Math.PI/180))+hightC*(Math.cos(40*Math.PI/180))*(Math.tan(20*Math.PI/180))+hightC/2);

          canvas.toBlob((result) => {
            resolve(result)
          },file.type,1);
      };
    };
  });
}
const beforeUpload2=(file,files)=>{
  if (file.size >10* 1024 * 1024) {
    Toast.show('请选择小于 10M 的图片')
    return null
  }
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = document.createElement('img');
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth*0.2;
        canvas.height = img.naturalHeight*0.2;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0,img.naturalWidth*0.2,img.naturalHeight*0.2);
        canvas.toBlob((result) => {
          resolve(result)
        },file.type,1);
      };
    };
  });
}
//上传提示
const passportTips=(<div className='tips'>
  <h3>Identity Information example</h3>
  <Image src={photo1}></Image>
</div>)

//上传提示
const avatarTips=(<div className='tips'>
  <h3>avatarTips</h3>
  <Image src={photo2}></Image>
  </div>)
  // eslint-disable-next-line react-hooks/exhaustive-deps
// const address1=(data)=>{
//   data.forEach(item=>{
//     item.label=item['name']
//     item.value=item['code']
//   })
// }

//设置楼选项
const address=(data)=>{
    data.forEach(item=>{
    item.label=item['name']
    item.value=item['code']
    item.children=item['child']
    if(item.child && item.child.length>0){
      address(item.child)
    }
  })
}

useEffect(()=>{
  if(fileList2.length>0){
    document.getElementsByClassName('upload-image2')[0].style.display='none'
  }
  if(fileList1.length>0){
    document.getElementsByClassName('upload-image1')[0].style.display='none'
  }
},[fileList2,fileList1])
useEffect(()=>{
  setIsLoading1(true)
  setIsLoading(true)
  axios.post(`/api/getAdderssInfoByPropCode/${propCode}`).then(res=>{
      setLogoPath(res.data.data.logoPath)
      setName(res.data.data.name)
      address(res.data.data.child)
      setOptions1([res.data.data.child])
      if(property){
        if(res.data.data.child.find((item)=>item.code===property[0]).child.length>0){
          setOptions2(res.data.data.child.find((item)=>item.code===property[0]).child)
          setIsLoading1(false)
          setIsAbled(false)
        }else{
          setOptions2([[]])
          setIsLoading1(false)
          setIsAbled(false)
        }
      }
      if(urlArr[1]==='pre-register'){
        if(res.data.data.child.find(item=>item.code===cssUniqueKeyArr[2]) && 
        res.data.data.child.find(item=>item.code===cssUniqueKeyArr[2]).child.find(item1=>item1.code===cssUniqueKeyArr[3]) &&
        res.data.data.child.find(item=>item.code===cssUniqueKeyArr[2]).child.find(item1=>item1.code===cssUniqueKeyArr[3]).child.find(
          item2=>item2.code===cssUniqueKeyArr[4])){
          form.setFieldsValue({
            deptCode:res.data.data.child.find(item=>item.code===cssUniqueKeyArr[2]).name,
            address:res.data.data.child.find(item=>item.code===cssUniqueKeyArr[2]).child[0].name+'-'+
            res.data.data.child.find(item=>item.code===cssUniqueKeyArr[2]).child.find(item1=>item1.code===cssUniqueKeyArr[3]).child[0].name+'-'+
            res.data.data.child.find(item=>item.code===cssUniqueKeyArr[2]).child.find(item1=>item1.code===cssUniqueKeyArr[3]).child.find(
              item2=>item2.code===cssUniqueKeyArr[4]).child[0].name
          })
          setUnit(res.data.data.child.find(item=>item.code===cssUniqueKeyArr[2]).child[0].name+'-'+
            res.data.data.child.find(item=>item.code===cssUniqueKeyArr[2]).child.find(item1=>item1.code===cssUniqueKeyArr[3]).child[0].name+'-'+
            res.data.data.child.find(item=>item.code===cssUniqueKeyArr[2]).child.find(item1=>item1.code===cssUniqueKeyArr[3]).child.find(
              item2=>item2.code===cssUniqueKeyArr[4]).child[0].name)
        }else{
          Toast.show({
            duration:'2000',
            content:'cssUniqueKey is wrong'
          })
        }
       }
      setIsLoading(false)
  }).catch((error)=>{
    Toast.show({
      duration:'2000',
      content:error.toString()
    })
  })
// eslint-disable-next-line react-hooks/exhaustive-deps
},[property])
useEffect(()=>{
  if(visitorId){
    axios.post(`/api/getUserInfoByVisitor/${visitorId}`).then(res=>{
      if(res.data.data.passportPhoto && res.data.data.selfiePhoto){
        document.getElementsByClassName('upload-image2')[0].style.display='none'
        document.getElementsByClassName('upload-image1')[0].style.display='none'
        setFileList1(res.data.data.passportPhoto)
        setFileList2(res.data.data.selfiePhoto)
        res.data.data.passportPhoto=[{url:res.data.data.passportPhoto}]
        res.data.data.selfiePhoto=[{url:res.data.data.selfiePhoto}]
        form.setFieldsValue(res.data.data)
      }
    }).catch((error)=>{
      Toast.show({
        duration:'2000',
        content:error.toString()
      })
    })
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])
useEffect(()=>{
  const instance = axios.create({
    baseURL: 'https://cssdecisions.com',
  });
  const requestData = {
    propCode: propCode
  }; 
  const headers = {
    'Authorization': 'MLKkvRTN8hQmWVHUaTT2M8MpSo', 
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*' 
  };
  instance.post('/CSSVMSAPI2-CORE/API/Visitor/GetVisitPurpose', requestData, { headers })
  .then(response => {
    console.log('Response:', response.data);
    const {Lists} = response.data
    if(Array.isArray(Lists)){
      const newLists = Lists.map((item,index)=>{return { label: item.purposename, value: index + 1 }})
      setVisitPurposeColumns([newLists])
    }
  })
  .catch(error => {
    console.error('Error:', error);
  });
},[])
 return (
  <ConfigProvider locale={enUS}>
    {!netWork?
    <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'150px 95px 0'}}>
      <Image src={network} width={200} height={200} />
      <h2 style={{padding:'24px 0 16px'}}>Network error</h2>
      <p className='success-content'>Cannot post  api/upload 502</p>
      <Button  color='primary' size='middle'  onClick={()=>window.history.go(0) }>Refresh</Button>
    </div>:
    <>
    {loading?<>
      <div className='register-hearder'>
        <div className='register-hearder-center'>
          <img src={logoPath} alt="" style={{width:124,height:124}}/>
        </div>
        <div className='register-hearder-title'>Welcome to</div>
        <div className='register-hearder-title'>{name}</div>
        <div className='register-hearder-title'>Please register information.</div>
      </div>
      <Form
        requiredMarkStyle='none'
        name='form'
        form={form}
        onFinish={onFinish}
        initialValues={{cardType:1}}
        footer={<>
          <div style={{display:'flex',alignItems:'center'}}>
          <Checkbox onChange={(value)=>setAgree(value)} ref={checkRef} checked={agree} />
          &nbsp;
          <span className='text-gray-deep'>I have read and agreed to the </span>
          &nbsp;
          <span className='text-blue' onClick={()=>{
              Dialog.alert({
                title:'Privacy Clause',
                content: 
                // <div className="info" dangerouslySetInnerHTML={{__html: }}></div>
                <>
                  <img src={cssLogo} alt="" />
                  <p className='clause-logo'>CSS Decisions Sdn. Bhd.</p>
                  <p className='clause-logo' style={{marginBottom:30}}>CSS Strata Management Sdn. Bhd.</p>
                  <p className='clause-title'>PRIVACY POLICY</p>
                  <p className='clause-content'>CSS™ respects your privacy and is committed to protecting your personal information that you provide to us. This Privacy Policy explains our policies and practices regarding the use and disclosure of your personal information by CSS. CSS reviews and updates this Privacy Policy from time to time as needed without notice. By using our CSS services, you consent to the collection, use, and disclosure of your personal information by CSS as explained below.</p>
                  <p className='clause-title2'>What Information Do We Collect?</p>
                  <p className='clause-content'>When you use our services, you provide us with data that may include the following:</p>
                  <p className='clause-content'>Name and contact data. Your first and last name, email address, postal address, phone number, and other similar contact data.</p>
                  <p className='clause-content'>Credentials. Passwords, password hints, and similar security information used for authentication and account access.</p>
                  <p className='clause-content'>Demographic data. Data about you such as your age, gender, country, and preferred language.</p>
                  <p className='clause-content'>Payment data. Data to process payments, such as your payment instrument number (e.g., a credit card number), and the security code associated with your payment instrument.</p>
                  <p className='clause-content'>Subscription and licensing data. Information about your subscriptions, licenses, and other entitlements to our products.</p>
                  <p className='clause-title2'>How Do We Use The Information We Collect?</p>
                  <p className='clause-content'>If not otherwise described in this policy, we may process personal information collected from or about you for any of the following purposes:</p>
                  <ul className='clause-content'>
                    <li>To respond to and record your requests, whether it be a product purchase, a downloaded whitepaper, or product demonstrations and evaluations.</li>
                    <li>To provide the products, communicate with you about our products and fulfill any contractual obligations we may have with you including providing post-purchase technical support, implementation services, and updates for the products.</li>
                    <li>To set up your account and to provide our services to you and to your End Users.</li>
                    <li>To manage and verify your account and the identity of users of the products.</li>
                    <li>Processing of payments.</li>
                    <li>To deliver live or online events such as training seminars or conferences, including third party events we sponsor.</li>
                    <li>The server log files, to allow you unhindered and trouble-free access to our services and to improve the security of our systems.</li>
                    <li>To support and troubleshoot our services and to respond to queries.</li>
                    <li>To respond to feedback, survey data, and product reviews, including for product improvement purposes.</li>
                    <li>To provide product testing or product demos.</li>
                    <li>For any other legal, business, or marketing purposes that are not inconsistent with the terms of this policy.</li>
                    <li>To protect against, investigate and prevent fraudulent, unauthorized or unlawful acts against us, you and third parties.</li>
                  </ul>
                  <p className='clause-content'>Further, we process your data in order to inform you about our products and services by means of our newsletter or other information. Sending you such information is based on your consent.</p>
                  <p className='clause-content'>In addition, we process your data to comply with statutory reporting requirements, audit, compliance, risk management, court summonses, court decisions or other court orders.</p>
                  <p className='clause-title2'>Disclosure</p>
                  <p className='clause-content'>We share your personal data as necessary to complete any transaction or provide any product you have requested or authorized or otherwise fulfil your request, to inter alia CSS controlled affiliates and subsidiaries.</p>
                  <p className='clause-content'>We may share your information with our business partners, resellers, and subcontractors who use the information to fulfill product orders, process payments, develop and improve products, contact you about product orders or in connection with the performance, operation, and support of the products, perform marketing or consulting services, respond to your requests, or as otherwise appropriate for us to provide you with the products.</p>
                  <p className='clause-content'>We may furthermore disclose your information to:</p>
                  <p className='clause-content'>(i)comply with any applicable law, regulation, legal process or governmental request.</p>
                  <p className='clause-content'>(ii)investigate, detect, prevent or take action regarding illegal activities or other wrongdoing, suspected fraud or security issues.</p>
                  <p className='clause-content'>(iii)prevent harm to the rights, property or safety of us, our users, yourself or any third party.</p>
                  <p className='clause-title2'>Data Security</p>
                  <p className='clause-content'>We are committed to making reasonable efforts, in accordance with market best practices, to ensure the security, confidentially and integrity of the Personal Information. We take great care in implementing and maintaining the security of our System and the Personal Information.</p>
                  <p className='clause-content'>Access to the Personal Information is based on the ‘least to know’ concept together with role-based access control systems, ensuring only authorized access to the Personal Information. We employ market best practice security measures to ensure the safety of your End Users’ Personal Information and prevent unauthorized use of any such information.</p>
                  <p className='clause-content'>Although we take steps to safeguard such information, we cannot be responsible for the acts of those who gain unauthorized access or abuse our System, and we make no warranty, express, implied or otherwise, that we will prevent such access.</p>
                  <p className='clause-title2'>Retention Of Personal Data</p>
                  <p className='clause-content'>At any time, you or your End Users may request to change, update, correct errors or delete Personal Information by emailing us at supports@cssdecisions.com</p>
                  <p className='clause-content'>Please note that unless you instruct us otherwise we retain the information we collect for as long as needed to provide our services and to comply with our legal obligations, resolve disputes and enforce our agreements.</p>
                  <p className='clause-content'>CSS has an archiving process that determines the period during which the information is available. After the archiving period is reached, the information is archived, and Client can restore it once needed. Our default retention policy is 90 days.</p>
                  <p className='clause-title2'>What Are Your Rights?</p>
                  <p className='clause-content'>You may contact us at supports@cssdecisions.com any time and request:</p>
                  <p className='clause-content'>To access, correct or update any personal data relating to you that might be inaccurate, obsolete, incomplete or misleading.</p>
                  <p className='clause-content'>In accordance with the PDPA, once we receive a request, we will acknowledge the receipt of such request. For data amendment and correction, we will consider if the information requires amendment. If there is any disagreement, then we specify the reason in clearly stated terms such as where rights of others will be violated, or regulatory functions will be affected. CSS has rights to refuse to comply with your request for access or correction to the Personal Information and give you a written reason for such an action.</p>
                  <p className='clause-title2'>Contact Us</p>
                  <p className='clause-content'>If there are any questions regarding this Privacy Policy, you may contact us through the following details:</p>
                  <p className='clause-content'>Email Address : supports@cssdecisions.com / enquiry@cssdecisions.com </p>
                  <p className='clause-content'>Phone Number : +603-9200 4096</p>
                  <p className='clause-content'>Fax Number	: +603-9200 4249</p>
                  <p className='clause-content'>Address	: C-3A-5 Level 5 Block C, Viva Home (UE3) 85 Jalan Loke Yew, 55200 Kuala Lumpur.</p>
                </>
                ,
                confirmText:'OK',
                onConfirm:()=>{
                  checkRef.current.check()
                }
                })
            }}>Privacy Clause</span>
          </div>
          <Button block type='submit' style={{backgroundColor:'#185CC3',color:'#FFFFFF'}} size='large' className='margin-tm' onClick={()=>{
            if(!date){
              setIsSelect(false)
            }
          }}>
            Register
          </Button>
          <p className='text-gray-deep2'>Powered by Acclivis</p>
          </>
        }
      >
        <Form.Header>Personal Information</Form.Header>
        <Form.Item name='fullName'
          disabled={visitorId}
         label={<><span>Full Name</span><span className='adm-form-item-required-asterisk1'
          >*</span></>} rules={[{ required: true ,message:'The content can not be blank'}]} >
          <Input placeholder='Please input' clearable/>
        </Form.Item>
        <Form.Item
         name='contactNumber' label={<><span>Contact Number</span><span className='adm-form-item-required-asterisk1'
          >*</span></> } 
          rules={[{ required: true,message:'The content can not be blank' },
          {
            pattern: /^\s*([^\s]\s*){8,20}$/ ,
            message: 'Incorrect content',
          }]} disabled={visitorId}>
          <Input placeholder='Please input' clearable/>
          {/* <MobileField /> */}
        </Form.Item>
        <Form.Item name='email' label={<><span>Email</span><span className='adm-form-item-required-asterisk1'
          >*</span></>} 
          rules={[{ required: true,message:'The content can not be blank' },
          {
            pattern: /[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/ ,
            message: 'Incorrect content',
          }]} disabled={visitorId}>
          <Input placeholder='Please input' clearable/>
        </Form.Item>
        <Form.Item name='cardType' label={<><span>Identity Informaiton</span><span className='adm-form-item-required-asterisk1'
          >*</span></>} disabled={visitorId}>
          <Radio.Group onChange={(value)=>{
            setIdentity(value)
            Noref.current.clear()
          }}>
            <Space >
              <Radio value={1}>NRIC</Radio>
              <Radio value={2}>PASSPORT</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name='passportNo'disabled={visitorId}  rules={[{ required: true,message:'The content can not be blank'}
      //   ,{
      //   pattern: identity===1? /^[STFG]\d{7}[A-Z]$/:
      //   /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/,
      //   message: 'Incorrect content',
      // }
      ]}  className='passportNo'>
          <Input placeholder='Please input' clearable ref={Noref}/>
        </Form.Item>
        <Form.Item label={<><span>Upload Photo</span><span className='adm-form-item-required-asterisk1'
          >*</span></>} disabled={visitorId}>
          {/* <Space direction='horizontal'> */}
            <div style={{display:'flex',justifyContent:'left'}}>
              <Space direction='vertical' style={{marginRight:10}}>
                <Form.Item name='passportPhoto' noStyle rules={[{ required: true,message:'The  passport photo can not be blank' }]} 
                >
                  <ImageUploader
                    accept='image/jpeg,image/jpg,image/png'
                    value={fileList1}
                    onChange={setFileList1}
                    beforeUpload={beforeUpload}
                    maxCount={1}
                    upload={Upload1}
                    onDelete={() => {
                    return Dialog.confirm({
                      content: 'Delete this photo？',
                      cancelText:'Cancel',
                      confirmText:<span style={{color:'#F5278B'}}>Delete</span>,
                      onConfirm:()=>document.getElementsByClassName('upload-image1')[0].style.display='block'
                      })
                    }}
                  >
                    <span></span>
                  </ImageUploader>
                </Form.Item>
                <Image className='upload-image1' width={80} height={80} src={add} onClick={() =>{
                  Dialog.alert({
                    onConfirm: () => {
                      document.getElementsByClassName('adm-image-uploader-input')[0].click()
                      // setImgIsAble(false)
                    },
                    confirmText:'OK',
                    content:passportTips                   
                  })
                }
                 }
                />
                <div className='img-underText'>IC/Passport Photo</div>
              </Space>
              <Space direction='vertical'>
                <Form.Item name='selfiePhoto' noStyle className='passportPhoto' rules={[{ required: true,message:'The selfie photo can not be blank'  }]} >
                  <ImageUploader
                    accept='image/jpeg,image/jpg,image/png'
                    value={fileList2}
                    onChange={setFileList2}
                    maxCount={1}
                    upload={Upload2}
                    beforeUpload={beforeUpload2}
                    // onPreview={onPreview}
                    onDelete={() => {
                    return Dialog.confirm({
                      content: 'Delete this photo？',
                      cancelText:'Cancel',
                      confirmText:<span style={{color:'#F5278B'}}>Delete</span>,
                      onConfirm:()=>document.getElementsByClassName('upload-image2')[0].style.display='block'
                      })
                    }}
                  >
                    <div></div>
                  </ImageUploader>
                </Form.Item>
                <Image className='upload-image2' width={80} height={80} src={add} onClick={() =>
                  Dialog.alert({
                    onConfirm: () => {
                      if(fileList1.length>0){
                        document.getElementsByClassName('adm-image-uploader-input')[0].click()
                      }else{
                        document.getElementsByClassName('adm-image-uploader-input')[1].click()
                      }
                    },
                    confirmText:'OK',
                    content:avatarTips
                  })
                 }
                />
                <div className='img-underText' >Selfie/Take Ownphoto</div>
              </Space> 
            </div>
          {/* </Space> */}
        </Form.Item>
        <Form.Item name='vehicleNumber' label='Vehicle No.' disabled={visitorId}>
          <Input  clearable placeholder='Not required'/>
        </Form.Item>
        <Form.Item name='drivingLicense' label='Driving License' disabled={visitorId}
      // {
      //   pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}(\d|x|X)$/,
      //   message: 'Incorrect content',
      // }
      >
          <Input  clearable placeholder='Not required'/>
        </Form.Item>
        <Form.Header>Access Information</Form.Header>
        <Form.Item
          name='deptCode'
          label={<><span>Department Description</span><span className='adm-form-item-required-asterisk1'
          >*</span></>}
          trigger='onConfirm'
          onClick={(e,ref) => {
            ref.current.open()
          }}
          rules={[{ required: true,message:'The content can not be blank'  }]}
          disabled={urlArr[1]==='pre-register'}
        >
          {urlArr[1]!=='pre-register'?<Picker
            loading={isLoading}
            columns={options1}
            cancelText='Cancel'
            confirmText='Ok'
            onConfirm={(value,e)=>{
              if(value.length>0){
                setProperty(value)
                setdeptDscp(e.items[0].label)
                form.resetFields(['address','cssUniqueKey'])
              }
            }}
            >
            {value =>
                value.length>0?<div className='text-overflow'>{value[0].label}</div>:<span className='text-gray'>Please select</span>
            }
            </Picker>:<Input placeholder='Please input'/>}
        </Form.Item>
        <Form.Item
          name='address'
          label={<><span>Address</span><span className='adm-form-item-required-asterisk1'
          >*</span></>}
          trigger='onConfirm'
          onClick={(e,ref) => {
            ref.current.open()
          }}
          rules={[{ required: true,message:'The content can not be blank' }]}
          disabled={isAbled || urlArr[1]==='pre-register'}
        >
          {urlArr[1]!=='pre-register'?<CascadePicker
            loading={isLoaing1}
            options={options2}
            cancelText='Cancel'
            confirmText='Ok'
            title={<span className='text-title'>Please select address(Block-Floor-Unit)</span>}
            onConfirm={(value,extend)=>{
              setUnit(extend.items[2].label)
              setIsLoading2(true)
              form.resetFields(['cssUniqueKey'])
              if(value.length>0&&value[0]!==null){
                axios.post(`/api/area/getCssUniqueListByUnitCode/${value[2]}`).then(res=>{
                  res.data.data.cssUniqueDTOS.forEach(item=>{
                    item.label=item['name']
                    item.value=item['key']
                  })
                  if(res.data.data.cssUniqueDTOS.length>0){
                    setOptions3([res.data.data.cssUniqueDTOS])
                    setIsAbled2(false)
                    setIsLoading2(false)
                  }else{
                    setOptions3([])
                    setIsAbled2(false)
                    setIsLoading2(false)
                  }
                })
              }
            }}
            >
            {value =>
                value.length>0&&value[0]!==null?(value[0].label+'-'+value[1].label+'-'+value[2].label) :<span className='text-gray'>Please select</span>
            }
            </CascadePicker>:<Input placeholder='Please input'/>}
        </Form.Item>
        {urlArr[1]==='walk-in'&&<Form.Item
          name='cssUniqueKey'
          label={<><span>Resident</span><span className='adm-form-item-required-asterisk1'
          >*</span></>}
          trigger='onConfirm'
          onClick={(e,ref) => {
            ref.current.open()
          }}
          rules={[{ required: true,message:'The content can not be blank'  }]}
          disabled={isAbled2}
        >
          <Picker
            loading={isLoaing2}
            columns={options3}
            cancelText='Cancel'
            confirmText='Ok'
            rules={[{ required: true,message:'The content can not be blank'  }]}
            >
            {value =>{
              return ( value.length>0&&value[0]!==null)?value[0].label :<span className='text-gray'>Please select</span>
            }
            }
            </Picker>
        </Form.Item>}
        <Form.Item
          name='visitPurpose'
          label={<><span>Visit Purpose</span><span className='adm-form-item-required-asterisk1'>*</span></>}
          trigger='onConfirm'
          onClick={(e,ref) => {
            ref.current.open()
          }}
          rules={[{ required: true,message:'The content can not be blank' }]}
        >
          <Picker
            columns={visitPurposeColumns}
            cancelText='Cancel'
            confirmText='Ok'
            onConfirm={(value,e)=>{
                setPurpose(e.items[0].label)
              }
            }
            >
            {value =>
                value.length>0?value[0].label :<span className='text-gray'>Please select</span>
            }
            </Picker>
        </Form.Item>
        <Form.Item
          layout='horizontal'
          childElementPosition='right'
          style={{'--prefix-width': '9.8em',fontSize:14}}
          initialValue={1}
          rules={[
            {
              max: 99,
              min: 1,
              type: 'number',
              message:'Please select at least one person'
            },
            { required: true}
          ]}
          name='visitorNumber'
          label={<><span>Number of Visitor</span><span className='adm-form-item-required-asterisk1'
          >*</span></>}
        >
          <Stepper digits={0}  max={99} min={1} disabled/>
        </Form.Item>
        <Form.Item
          // name='visitDate'
          label={<><span>Visit Date</span><span className='adm-form-item-required-asterisk1'
          >*</span></>}
          trigger='onConfirm'
          onClick={(e, datePickerRef) => {
            setVisible(true)
          }}
        >
          <Popup
            visible={visible}
            onMaskClick={() => {
              setVisible(false)
            }}
            bodyStyle={{
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              minHeight: '35vh',
            }}
          >
            <div className='adm-picker-header'>
              <a role='button' className='adm-picker-header-button'
               style={{fontSize:15,color:'#185CC3'}} onClick={()=>setVisible(false)}>Cancel</a>
              <div className='adm-picker-header-title'></div>
              <a role='button' className='adm-picker-header-button'
               style={{fontSize:15,color:'#185CC3'}} onClick={()=>{
                if(startTime && endTime){
                  setVisible(false)
                  setDate({startTime,endTime})
                  setIsSelect(true)
                }else{
                  Toast.show({
                    duration:'2000',
                    content: <span className='word-break'>Please select start time-end time</span>,
                  })
                }
                }}>Ok</a>
            </div>
            <div className='date-input'>
              <span className={isDate==='start'?'time-inputS':'time-input'} onClick={()=>setIsDate('start')}>
                {startTime?startTime:<span className='text-gray'>Choose start time</span>}
              </span>
              <span className='time--'>-</span>
              <div className={isDate==='end'?'time-inputS':'time-input'} onClick={()=>setIsDate('end')}>
                {endTime?endTime:<span className='text-gray'>Choose end time</span>}
              </div>
            </div>
            {isDate==='start'?
            <DatePickerView
              onChange={val => {
                if(dayjs(val).format('YYYY/MM/DD HH:mm:ss') < dayjs(new Date()).format('YYYY/MM/DD HH:mm:ss')){
                  Toast.show({
                    duration:'2000',
                    content: <span className='word-break'>Please select a time greater than the current time</span>,
                  })
                }else{
                  if(dayjs(val).format('YYYY/MM/DD HH:mm:ss')<endTime || !endTime){
                    setStartTime(dayjs(val).format('YYYY/MM/DD HH:mm:ss'))
                  }else{
                    Toast.show({
                      duration:'2000',
                      content: <span style={{wordWrap:'break-word'}}>Please select less than end time</span>,
                    })
                  }
                }
              }}
              defaultValue={new Date(dayjs(new Date()).valueOf()+1000*60)}
              precision='minute'
              renderLabel={labelRenderer}
              // filter={endTime&&dateFilter1}
            />:<DatePickerView
                  onChange={val =>{
                    if(dayjs(val).format('YYYY/MM/DD HH:mm:ss') < dayjs(new Date()).format('YYYY/MM/DD HH:mm:ss')){
                      Toast.show({
                        duration:'2000',
                        content: <span className='word-break'>Please select a time greater than the current time</span>,
                      })
                    }else{
                      if(dayjs(val).format('YYYY/MM/DD HH:mm:ss')>startTime || !startTime){
                        setEndTime(dayjs(val).format('YYYY/MM/DD HH:mm:ss'))
                      }else{
                        Toast.show({
                          duration:'2000',
                          content: 'Please select greater than start time',
                        })
                      }
                    }
                  }}
                  defaultValue={new Date(dayjs(new Date()).valueOf()+1000*60)}
                  precision='minute'
                  renderLabel={labelRenderer}
                  // filter={startTime&&dateFilter2}
            />}
          </Popup>
          {date ? date.startTime.slice(0,-3)+'-'+date.endTime.slice(0,-3) : <span className='text-gray'>Please select</span>}
          <p className='check'style={{display:isSelect?'none':'block'}}>The content can not be blank</p>
        </Form.Item>
      </Form>
      </>:
      <>
        <div className='center'>
          <SpinLoading style={{ '--size': '48px'}} />
          <div className='success-content'>Please be patient, we are processing your registration...</div>
        </div>
      </>} 
    </>
}
    </ConfigProvider>

  )
  
}
VisitorMobile.propTypes = {second: PropTypes.number}
export default VisitorMobile ;




  

const columns = [['86', '01', '02', '03']]



const MobileField = ({
  value = { preValue: '86', realValue: '' },
  onChange,
}) => {
  const [visible, setVisible] = useState(false)

  const triggerValue = (changedValue) => {
    onChange({ ...value, ...changedValue })
  }

  const onRealValueChange = (value) => {
    triggerValue({ realValue: value })
  }

  const onPreValueChange = (value) => {
    const v = value[0]
    if (v === null) return
    triggerValue({ preValue: v })
  }
  return (
    <>
      <Space align='center'>
        <Space align='center' onClick={() => setVisible(true)}>
          <div>+{value.preValue}</div>
          <DownOutline />
        </Space>
        <Input
          placeholder='请输入手机号'
          value={value.realValue}
          onChange={onRealValueChange}
        />
      </Space>
      <Picker
        columns={columns}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
        value={[value.preValue]}
        onConfirm={onPreValueChange}
      />
    </>
  )
}