import React from 'react';
import VisitorH5 from "./VisitorH5";
import VisitorPC from "./VisitorPC";
import RegisterMobile from './RegisterMobile'

class Visitor extends React.Component {

    state = {
        type: document.body.clientWidth > 800 ? 'PC' : 'H5'
    }

    constructor(props) {
        super(props);
        if (window.location.pathname.substring(1)) {
            const array = window.location.pathname.substring(1).split('/');
            this.state.identificationCode = array[0];
        }else{
            this.state.identificationCode = [];
        }

    }

    render() {
        const {type, identificationCode} = this.state;
        return (
            <div>
                {
                    // type === 'H5'
                    // ? 
                    // <VisitorH5 identificationCode={identificationCode}/>
                    <RegisterMobile identificationCode={identificationCode}/>
                    // : <VisitorPC identificationCode={identificationCode}/>
                }
            </div>
        )
    }
}

export default Visitor;

