import React from 'react';
import Register from "./Page/Register";
import './App.css';
import { BrowserRouter, Route, Routes,Navigate } from 'react-router-dom';
import Visitor from './Page/Visitor';
import Success from './Page/Success'


function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Routes>
        {/* <Route path="/" element={<Navigate to='' />} /> */}
        {/* <Route path="/*" element={<Visitor />} /> */}
        <Route path="/:type/:propCode/*" element={<Visitor />} />
        <Route path="/:register" element={<Register />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
